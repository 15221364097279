import select from 'dom-select';
import { serialize } from 'dom-form-serializer/dist/dom-form-serializer';

export default (el) => {
  const ui = {
    el,
    form: select('#filters--library', el), // filters parent form
    filters: select.all('.form__item .input', el), // the actual <input />s
    filterGroups: select.all('[id$="-filters"]', el),
    cardsParent: select('.grid--library', el), // parent container of cards
    cards: select.all('.card', el),
    filtersReset: select.all('.filters-reset', el),
    buttons: select.all('button', el),
    count: select('#card-count span', el)
  };

  // optional messaging and data options that might come from CMS
  ui.nopeMsg = ui.el && ui.el.dataset.nullMessage ? ui.el.dataset.nullMessage : 'There are no results matching your choices.';
  ui.resetMsg = ui.el && ui.el.dataset.resetMsg ? ui.el.dataset.resetMsg : 'Clear Filters';
  ui.dataSep = ui.el && ui.el.dataset.separator ? ui.el.dataset.separator : '|';

  // deselect all filters
  const resetFilters = (ev) => {
    ev.preventDefault();

    ui.filters.forEach((filter) => {
      filter.checked = false; // eslint-disable-line
    });

    getCardCount();
  };

  const getCardCount = () => {
    const cardCount = document.querySelectorAll('.filters__content .card:not(.filtered--hide)').length
    ui.count.innerText = cardCount;
    return;
  }

  const showCard = (card) => {
    card.classList.add('filtered--show');
    card.classList.remove('filtered--hide');
    return card;
  };

  const hideCard = (card) => {
    card.classList.remove('filtered--show');
    card.classList.add('filtered--hide');
    return card;
  };

  const hideAllCards = () => {
    ui.cards.forEach((card) => {
      hideCard(card);
    });
    getCardCount();
  };

  const showAllCards = () => {
    ui.cards.forEach((card) => {
      showCard(card);
    });
    getCardCount();
  };

  // remove no results message
  const notSorry = () => {
    const nope = select('.nope-wrap', el);

    if (nope) {
      ui.cardsParent.removeChild(nope);
    }
  };

  const handleReset = (ev) => {
    resetFilters(ev);
    showAllCards();
    notSorry();
    getCardCount();
  };


  const setFiltersByQuerystring = () => {
    const querystring = new URLSearchParams(document.location.search.substring(1));
    const filterParam = querystring.get("filter");

    if (!filterParam) {
      return;
    }

    const filterset = filterParam.split(ui.dataSep);
    filterset.forEach(value => {
      // worst.filter.logic.ever
      ui.filters.forEach((filter) => {
        if(filter.id === value){
          filter.checked = true;
        }
      });

      // filters are set, now do something about it
      handleChange();
    });
  };

  const syncUrlQuerystring = (filterKeysArray) => {
    //Parse url, get querystring
    const querystring = new URLSearchParams(document.location.search.substring(1));
    querystring.set("filter",  filterKeysArray.join(ui.dataSep));
    //live! update the url querystring
    window.history.replaceState({}, '', `${window.location.pathname}?${querystring}`);
  };

  // build no results message and append to cards container
  const saySorry = () => {
    const wrap = document.createElement('div');
    const nope = document.createElement('h3');
    const msg = document.createTextNode(ui.nopeMsg);
    const reset = document.createElement('button');
    const resetMsg = document.createTextNode(ui.resetMsg);

    wrap.classList.add('text--align-center', 'nope-wrap', 'rhythm--large');
    wrap.setAttribute('style', 'grid-column: 1 / end');

    nope.classList.add('card--nope', 'heading', 'heading--h3');
    nope.appendChild(msg);

    reset.classList.add('button', 'button--primary', 'button--size-large');
    reset.appendChild(resetMsg);

    wrap.appendChild(nope);
    wrap.appendChild(reset);

    notSorry();
    ui.cardsParent.appendChild(wrap);
    reset.addEventListener('click', handleReset);
  };

  // convert snake_case and kabob-case to camelCase
  const snakeToCamel = (str) => str.replace(
    /([-_][a-z])/g,
    (group) => group.toUpperCase()
      .replace('-', '')
      .replace('_', '')
  );

  // converts filter name to data attribute
  const idToDataset = (id) => {
    const name = id.split('.')[0];

    return snakeToCamel(name);
  };

  // filters an object to only items that aren't null
  const trueObj = (obj) => {
    return Object.keys(obj).reduce((acc, cv) => {
      if (obj[cv]) {
        acc[cv] = obj[cv]; // eslint-disable-line
      }

      return acc;
    }, {});
  };

  // create object of arrays of inputs by group
  const filterGroupsInputObj = () => {
    const output = {};

    // loop over groups, find inputs in group,
    // push to array in output object
    ui.filterGroups.forEach((group) => {
      const id = group.id.split('-filters')[0];
      const inputs = select.all('.input', group);

      return output[id] = inputs;
    });

    return output;
  };

  // sort selected inputs by existing filter groups
  const groupedSelectedinputs = (groupObj, groupArr, allSelected) => {
    // loop groups
    const filteredGroup = groupArr.map((group) => {
      // get inner array of inputs
      const innerArr = groupObj[group];
      // find selected inputs among groups
      const filteredSel = innerArr.filter((item) => allSelected.includes(item.id));

      return filteredSel;
    });

    // get rid of empty arrays
    const selectedGroups = filteredGroup.filter((fg) => fg.length > 0);

    return selectedGroups;
  };

  // where the actual filtering happens
  const handleChange = () => {
    const filterObject = serialize(ui.form); // { name: value }
    const selectedFilters = trueObj(filterObject);

    // if nothing's selected, reset & bail
    if (Object.keys(selectedFilters).length === 0 && selectedFilters.constructor === Object) {
      notSorry();
      showAllCards();
      window.history.replaceState({}, '', `${window.location.pathname}?`);
      return;
    }

    const filterKeysArray = Object.keys(selectedFilters); // array of selected names
    const groupedInputs = filterGroupsInputObj();
    const groupArr = Object.keys(groupedInputs);
    const selectedGroups = groupedSelectedinputs(groupedInputs, groupArr, filterKeysArray);

    // ensure the querystring is *nSync
    syncUrlQuerystring(filterKeysArray);

    // create array to push cards into
    const groupCards = [];

    // hide all cards
    // then loop over them and see which match selected filters by group
    ui.cards
      .map((card) => hideCard(card)).forEach((card) => {
        // loop over selected inputs by group
        // selectedGroups is an array of array
        for (let index = 0; index < selectedGroups.length; index++) {
          // if this group's results array doesn't yet exist, create it
          if (groupCards[index] == null) {
            groupCards[index] = [];
          }

          // get this group's array of selected inputs...
          const innerSelected = selectedGroups[index];

          // ...and loop over it
          innerSelected.forEach((input) => {
            // create a data attribute based off input
            const dataAttrName = idToDataset(input.id);
            // retrieve card value based on data attribute
            const cardValue = card.dataset[dataAttrName] ? card.dataset[dataAttrName].toLowerCase() : '';
            // allow for multiple values on a single card
            // per filter (eg, data-language="spanish|english")
            const valueArr = cardValue.split(ui.dataSep);
            // test if values match, ie card is in selected filter
            const yepnope = valueArr.filter((value) => value === input.value);

            // if card matches, push it into this group's array
            if (yepnope.length > 0) {
              groupCards[index].push(card);
            }
          });
        }
      });

    // find cards common to all three group arrays
    const commonCards = groupCards.shift().filter((group) => {
      return groupCards.every((card) => {
        return card.indexOf(group) !== -1;
      })

    });

    // ensure we have matching cards and show them
    if (commonCards.length > 0) {
      notSorry();
      commonCards.map((card) => showCard(card));
    } else {
      hideAllCards();
      saySorry();
    }

    // console.log(commonCards.length);
    getCardCount();
  };

  // if we're not filterable, bail early
  if (!ui.filters) {
    console.log('not filterable');
    return;
  }

  // Process the querystring and set filter checkboxes onLoad
  setFiltersByQuerystring();

  ui.filtersReset.forEach((filter) => {
    filter.addEventListener('click', (ev) => {
      handleReset(ev);
    });
  });

  // bind filter change event
  ui.filters.forEach((filter) => {
    filter.addEventListener('change', (ev) => {
      handleChange(ev);
    });
  });
};