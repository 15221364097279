import select from 'dom-select';

export default(el) => {
  const ui = {
    el,
    link: el.href,
    title: select('.heading').innerText
  };

  const state = {
    clicked: false
  };

  const validFileTypes = /(\.docx?|\.pdf|\.mp3|\.mp4|\.zip|\.txt|\.rtf|\.xlsx?|\.pptx?)/i;

  const pusher = (ev) => {
    if (!state.clicked) {
      ev.preventDefault();

      window.gtag('event', 'Resource download', {
        'event_category': 'resource',
        'event_label': `${ui.title} | ${ui.link}`
      });

      state.clicked = true;
      ui.el.click();
    }
  };

  if (ui.link && validFileTypes.test(ui.link)) {
    ui.el.addEventListener('click', pusher);
    ui.el.addEventListener('touchstart', pusher);
  }
};
